@import url('https://fonts.googleapis.com/css2?family=Host+Grotesk:ital,wght@0,300..800;1,300..800&display=swap');

/* Define CSS variables for light mode */
:root {
  --background-color: #ffffff;
  --text-color: #121212;
  --secondary-text-color: #555555;
  --link-color: #007bff;
  --link-hover-color: #0056b3;
  --palette-green: #2fad7c;
  --palette-blue: #0e4c80;
  --palette-lightgreen: #5dd98a;
  --palette-red: #EF6461;
  --palette-yellow: #E4B363;

  --button-primary-bg: linear-gradient(120deg, #25ae7b, #25ae7b, #074b82);
  --button-primary-hover-bg: linear-gradient(to right, #1d8d64, #216f3e);
  --button-primary-border: 2px solid transparent;
  --button-secondary-bg: transparent;
  --button-secondary-hover-bg: #d6d6d6;
  --button-secondary-border: 2px solid #121212;
  --button-border-radius: 50px;

  --card-bg: #f9f9f9;
  --border-color: #dddddd;
  --input-bg: #ffffff;
  --input-placeholder-color: #888888;
  --input-focus-border: #007bff;

  --skeleton-light: #f3f3f3;
  --skeleton-dark: #e6e6e6;

  --font-family: "Host Grotesk", sans-serif;
  --font-size-base: 18px;
  --border-radius: 20px;
  --transition-speed: 0.3s;

  --bg-light: #f9f9f9;
  --text-light: #ffffff;
  --text-dark: #121212;
  --primary: #007bff;
}

body {
  margin: 0;
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  background-color: var(--card-bg);
  color: #d6336c;
  padding: 4px 6px;
  border-radius: var(--border-radius);
}

/* Buttons */
button,
.cta-button {
  padding: 10px 20px;
  border: none;
  text-decoration: none;
  font-weight: bold;
  transition: all var(--transition-speed);
  font-family: var(--font-family);
  border-radius: var(--button-border-radius);
  text-align: center;
  display: block;
}

button:hover {
  background-color: var(--button-primary-hover-bg);
}

.cta-button:hover {
  text-decoration: none;
  color: var(--text-light);
  background-position: 0 0;
}

.cta-button.primary {
  color: var(--text-light);
  background: var(--button-primary-bg);
  background-size: 200% 200%;
  background-position: 90% 100%;
  border: var(--button-primary-border);
}

.cta-button.primary:hover {
  text-decoration: none;
  color: var(--text-light);
  background-position: -10px 0;
}

.cta-button.secondary {
  background-color: var(--button-secondary-bg);
  color: var(--text-dark);
  border: var(--button-secondary-border);
}

.cta-button.secondary:hover {
  background-color: var(--button-secondary-hover-bg);
}

/* Links */
a {
  color: var(--link-color);
  text-decoration: none;
}

a:hover {
  color: var(--link-hover-color);
  text-decoration: underline;
}

/* Skeleton loading */
@keyframes pulse {
  0% {
    background-color: var(--skeleton-light);
  }

  50% {
    background-color: var(--skeleton-dark);
  }

  100% {
    background-color: var(--skeleton-light);
  }
}

.skeleton {
  background: linear-gradient(90deg, var(--skeleton-light) 25%, var(--skeleton-dark) 50%, var(--skeleton-light) 75%);
  background-size: 200% 100%;
  animation: skeleton-pulse 1.5s infinite;
}

@keyframes skeleton-pulse {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

/* Utility classes */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.text-center {
  text-align: center;
}

.hidden {
  display: none;
}

/* Form inputs */
input,
textarea,
select {
  display: block;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid var(--border-color);
  background-color: var(--input-bg);
  color: var(--text-dark);
  border-radius: var(--border-radius);
  font-size: var(--font-size-base);
}

input::placeholder,
textarea::placeholder {
  color: var(--input-placeholder-color);
}

input:focus,
textarea:focus,
select:focus {
  border-color: var(--input-focus-border);
  outline: none;
}

/* Card component */
.card {
  background-color: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--text-dark);
}

/* Table styles */
table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--card-bg);
  color: var(--text-dark);
}

th,
td {
  padding: 10px;
  border: 1px solid var(--border-color);
}

th {
  background-color: var(--bg-light);
}

tr:nth-child(even) {
  background-color: var(--skeleton-light);
}

p {
  margin: 0 0 20px 0;
}