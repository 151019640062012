.register-container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
  }
  
  label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: left;
  }
  
  input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  input:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .register-button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
  }
  
  .register-button:hover {
    background-color: #0056b3;
  }
  
  .error-message {
    color: red;
    margin-bottom: 15px;
  }
  