.profile-container {
    max-width: 400px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 10px;
    background-color: var(--card-bg);
    color: var(--text-color);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .profile-picture {
    position: relative;
    cursor: pointer;
    margin-bottom: 15px;
  }
  
  .profile-pic {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 3px solid var(--border-color);
    object-fit: cover;
    transition: opacity 0.2s ease-in-out;
  }
  
  .profile-pic:hover {
    opacity: 0.8;
  }
  
  .profile-field {
    margin-bottom: 15px;
  }
  
  .profile-field label {
    font-weight: bold;
  }
  
  .editable {
    cursor: pointer;
    padding: 5px;
    border: 1px dashed var(--border-color);
    border-radius: 5px;
    display: inline-block;
  }
  
  input[type="text"],
  input[type="number"] {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid var(--border-color);
    background-color: var(--input-bg);
    color: var(--text-color);
  }
  
  button {
    margin-left: 10px;
    padding: 8px 12px;
    border-radius: 5px;
    border: none;
    background-color: var(--button-primary-bg);
    color: var(--text-light);
    cursor: pointer;
  }
  
  button:hover {
    background-color: var(--button-primary-hover-bg);
  }
  