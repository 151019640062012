/* General navbar styles */
.navbar {
    background-color: var(--dark-bg);
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    padding: 10px 0px 20px 0px;
    align-items: flex-start;
    border-bottom: 1px solid var(--border-color);
  }
  
  .navbar-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  
  .navbar-brand {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--text-color);
    text-decoration: none;
    display: flex;
  }

  .navbar-brand img {
    width: 50px;
  }
  
  .burger-menu {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: var(--text-color);
    cursor: pointer;
  }
  
  .navbar-links {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin-top: 10px;
  }
  
  .navbar-link {
    color: var(--text-color);
    text-decoration: none;
    padding: 10px 0;
    border-bottom: 1px solid var(--border-color);
  }
  
  .navbar-link:hover {
    background-color: var(--hover-bg);
  }

  .navbar-cta {
    padding: 10px 20px;
    background-color: var(--primary-color);
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .navbar-cta:hover {
    background-color: var(--hover-bg);
  }
  
  .logout-btn {
    background: none;
    border: none;
    color: var(--text-color);
    font-size: 1rem;
    text-align: left;
    padding: 10px 0;
    cursor: pointer;
  }
  
  .logout-btn:hover {
    background-color: var(--hover-bg);
  }
  
  