/* App.css */

/* Preloader Styles */
.preloader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white; /* Adjust for your light or dark mode */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0; /* Initially hidden */
  visibility: hidden; /* Initially hidden */
  transition: opacity 0.5s ease-in, visibility 0.5s ease-in;
}

.preloader-container.fade-in {
  opacity: 1;
  visibility: visible;
}

.preloader-container.fade-out {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in, visibility 0.5s ease-in;
}

@keyframes pulse {
  0%, 100% {
    opacity: 0.8;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
}

/* Content Container Styles */
.content-container {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in, visibility 0.5s ease-in;
}

.content-container.fade-in {
  opacity: 1;
  visibility: visible;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 45px;
  aspect-ratio: 1;
  --c:no-repeat linear-gradient(#2fad7c 0 0);
  background: var(--c), var(--c), var(--c);
  animation: 
    l18-1 1s infinite,
    l18-2 1s infinite;
}
@keyframes l18-1 {
 0%,100% {background-size:20% 100%}
 33%,66% {background-size:20% 20%}
}
@keyframes l18-2 {
 0%,33%   {background-position: 0    0,50% 50%,100% 100%}
 66%,100% {background-position: 100% 0,50% 50%,0    100%}
}