/* src/components/Home.css */

/* Container for the homepage */
.home-container {
  font-family: var(--font-family);
  color: var(--text-color);
  padding: 20px;
  background-color: var(--background-color);
}

.hero-image-container {
  max-width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  background-position: center;
}

/* Hero Section */
.hero-section {
  color: var(--text-color);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  height: calc(100vh - 150px);
}

.hero-section h1 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: var(--text-color);
  font-weight: 800;
}

.hero-section span {
  color: var(--link-color);
  /* Highlighted color */
}

/* Features Section */
.features-section,
.how-it-works {
  margin: 40px auto;
  max-width: 800px;
  color: var(--text-color);
  display: none;
}

.features {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.feature {
  margin: 20px;
  max-width: 250px;
  text-align: left;
  background-color: var(--card-bg);
  padding: 15px;
  border-radius: var(--border-radius);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.feature h3 {
  color: var(--link-color);
}

/* Footer */
.footer {
  margin-top: 40px;
  padding: 10px 0;
  background: var(--card-bg);
  color: var(--text-color);
  text-align: center;
  border-top: 1px solid var(--border-color);
  display: none;
}